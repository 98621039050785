import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { i18nConfig } from "./i18nConfig";

export async function initI18next() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18nConfig,
      ns: getInitialNamespaces(),
      backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
      detection: {
        order: ["cookie", "htmlTag"],
      },
    });
}
