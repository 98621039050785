import * as Sentry from "@sentry/remix";
import { ApolloProvider } from "@apollo/client";
import { RemixBrowser } from "@remix-run/react";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { getClient } from "./libs/apollo/apolloClient.client";
import { initI18next } from "./libs/i18n/initI18next.client";

import "~/tailwind.css";
import { ToastProvider } from "./hooks/useToast";

Sentry.init({
  dsn: "https://0e7ed9e30b9f26addb838bd6de752690@o4508143389769728.ingest.de.sentry.io/4508143465005136",
});

async function hydrate() {
  await initI18next();
  startTransition(() => {
    const client = getClient();
    hydrateRoot(
      document,
      <StrictMode>
        <I18nextProvider i18n={i18next}>
          <ApolloProvider client={client}>
            <ToastProvider>
              <RemixBrowser />
            </ToastProvider>
          </ApolloProvider>
        </I18nextProvider>
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
